import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import Paragraph from 'components/paragraph';

const ParagraphsListContainer = ({ children }) => (
  <Consumer>
    {() =>
      React.Children.map(children, child => <Paragraph>{child}</Paragraph>)
    }
  </Consumer>
);

ParagraphsListContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ParagraphsListContainer;

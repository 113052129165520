import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledParagraph = styled.p`
  margin: 0.5rem 0;
  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '2.6rem';
        default:
          return '2rem';
      }
    }};
  `};
`;

import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import PageTitle from 'components/page-title';
import StyledSpecializzazioniIntro from 'components/chi-sono/introbar';
import ParagraphsListContainer from 'containers/paragraphs-list';
import CompetenceListContainer from 'containers/competence-list/competence-list';
import {
  StyledContainer,
  StyledCompetenceContainer,
  ClearerElement,
  StyledCompetenceList,
  StyledImageContainer,
  StyledImage,
  StyledCaption,
  BiographyContainer,
  TextContainer,
  Title,
  Copy,
  SubTitle,
  FullRow,
} from 'components/chi-sono/chi-sono.css';

import Contacts from 'components/contacts/contacts';

import { graphql } from 'gatsby';

const ChiSono = ({ data }) => (
  <Layout>
    <StyledSpecializzazioniIntro image={data.chiSonoJson.introImage} />
    <PageTitle>{data.chiSonoJson.pageTitle}</PageTitle>
    <StyledContainer>
      <StyledImageContainer>
        <figure>
          <StyledImage
            fluid={
              data.chiSonoJson.sideImage
                ? data.chiSonoJson.sideImage.childImageSharp.fluid
                : {}
            }
            alt="Specializzazioni"
          />
          <StyledCaption>
            <Title>{data.chiSonoJson.photoTitle}</Title>
            <Copy>{data.chiSonoJson.subTitle}</Copy>
          </StyledCaption>
        </figure>
      </StyledImageContainer>
      <TextContainer>
        <BiographyContainer>
          <ParagraphsListContainer>
            <>
              Sono Lara Bartali, fiorentina di nascita vivo a Pistoia da qualche
              anno e qui lavoro come fisioterapista. Nel mio percorso tra Prato
              e Pistoia ho vissuto realt&agrave; riabilitative diverse:
              dall&apos;infortunio muscolo-scheletrico dello sportivo, alle
              problematiche posturali infantili e adolescenziali, dalla
              riabilitazione di gruppo o individuale nel settore neurologico, a
              quella pre- e post operatoria nel campo ortopedico.
            </>
            <>
              Rivolgo particolare attenzione all&apos;interazione con i più
              piccoli dove la riabilitazione diventa gioco e la prevenzione di
              disordini strutturali passa in primo piano.
            </>
            <>
              Ho imparato a non dare mai per scontato in ambito neurologico la
              propriocezione e quella la sensibilit&agrave; tattile che troppo
              spesso viene sottovalutata e se affrontata correttamente porta ad
              un recupero migliore.
            </>
            <>
              Anche i massaggi fanno parte della fisioterapia e non sono di poca
              importanza, anzi, sono fondamentali perché influiscono sulla
              tempistica della guarigione, che si tratti di semplici contratture
              ad esempio o di edemi da drenare.
            </>
          </ParagraphsListContainer>
        </BiographyContainer>
        <SubTitle>Il mio percorso</SubTitle>
        <BiographyContainer>
          <ParagraphsListContainer>
            <>
              La Dr.ssa Bartali si è laureata in Fisioterapia nel 2011 presso
              l&apos;Università degli Studi di Firenze, con votazione 110/110 e
              Lode. Ha poi ampliato la sua formazione partecipando a diversi
              Corsi di aggiornamento e conseguendo l&apos;Attestato di
              soccorritore di II°livello abilitato all&apos;uso del
              defibrillatore.
            </>
            <>
              Tra il 2012 e il 2013 ha lavorato Centro di Riabilitazione IDF di
              Antella (FI), occupandosi in particolare di Riabilitazione in
              ambito ortopedico post-chirurgico; dal 2013 al 2015 ha invece
              svolto la sua attività presso il Centro di Medicina Riabilitativa
              CMR di Prato.
            </>
            <>
              Dal gennaio 2015 lavora come Fisioterapista in libera professione
              presso il Centro Fisioterapico &quot;Life&quot; di Pistoia, dove
              si dedica in particolare alla Riabilitazione ortopedica in
              patologie acute e cornice, pre e post-chirurgica, sportiva, oltre
              all&apos;utilizzo di terapia fisica (Tecar, ultrasuoni, terapia
              antalgica). Svolge inoltre attivit&agrave; domiciliare.
            </>
          </ParagraphsListContainer>
        </BiographyContainer>
      </TextContainer>
    </StyledContainer>
    <FullRow>
      <SubTitle>Prestazioni</SubTitle>
      <StyledCompetenceContainer>
        <StyledCompetenceList>
          <CompetenceListContainer>
            <>Trattamento Fisioterapico</>
            <>Chinesiterapia Attiva</>
            <>Chinesiterapia Passiva</>
            <>Chinesiterapia Segmentaria</>
            <>Consulenza Fisioterapica Online</>
            <>Elettrostimolazione</>
            <>Elettrostimolazione Dinamica</>
            <>Esame Posturale</>
            <>Fisiokinesiterapia</>
            <>Fisioterapia</>
            <>Fisioterapia Di Gruppo</>
            <>Fisioterapia Sportiva</>
            <>Ginnastica In Gravidanza</>
            <>Ginnastica Posturale</>
            <>Kinesiologia</>
            <>Linfodrenaggio Manuale</>
            <>Linfodrenaggio Meccanico</>
            <>Manipolazione Delle Articolazioni</>
            <>Manipolazione Fasciale</>
            <>Manipolazioni</>
            <>Manipolazioni Vertebrali</>
            <>Massaggio Decontratturante</>
            <>Massaggio Dinamico Antistress</>
            <>Massaggio Posturale</>
            <>Massaggio Sportivo</>
            <>Massaggio Terapeutico</>
            <>Massoterapia</>
            <>Massoterapia Distrettuale</>
          </CompetenceListContainer>
        </StyledCompetenceList>
        <StyledCompetenceList>
          <CompetenceListContainer>
            <>Metodo Bobath</>
            <>Metodo Kabat</>
            <>Metodo Mckenzie</>
            <>Metodo Perfetti</>
            <>Prevenzione E Trattamento Mal Di Schiena In Gravidanza</>
            <>Prima Valutazione Fisioterapica Con Trattamento</>
            <>
              Prima Valutazione Fisioterapica Per Riabilitazione Pavimento
              Pelvico
              <br /> O Cranio-Mandibolare
            </>
            <>Prima Visita Fisioterapica</>
            <>Progetto Riabilitativo Individuale</>
            <>Riabilitazione Caviglia</>
            <>Riabilitazione Del Pavimento Pelvico</>
            <>Riabilitazione Del Piede</>
            <>Riabilitazione Della Mano</>
            <>Riabilitazione Dopo Ictus</>
            <>Riabilitazione Geriatrica</>
            <>Riabilitazione Ginocchio</>
            <>Riabilitazione Infantile</>
            <>Riabilitazione Motoria</>
            <>Riabilitazione Neurologica</>
            <>Riabilitazione Neuromotoria</>
            <>Riabilitazione Oncologica</>
            <>Riabilitazione Ortopedica</>
          </CompetenceListContainer>
        </StyledCompetenceList>
        <StyledCompetenceList>
          <CompetenceListContainer>
            <>Riabilitazione Pediatrica</>
            <>Riabilitazione Post Operatoria</>
            <>Riabilitazione Post Traumatica</>
            <>Riabilitazione Spalla</>
            <>Riabilitazione Sportiva</>
            <>Riabilitazione Viscerale</>
            <>Rieducazione Funzionale - Posturale - Propriocettiva</>
            <>Rieducazione Pavimento Pelvico</>
            <>Rieducazione Pelvica</>
            <>Rieducazione Posturale Globale</>
            <>Seduta Di Fisioterapia Domiciliare</>
            <>Taping Neuromuscolare</>
            <>Tecarterapia</>
            <>Tens Terapia</>
            <>Terapia Manuale</>
            <>Terapia Miofasciale</>
            <>Trattamento 3 Sedute</>
            <>Trattamento Dei Trigger Point</>
            <>Trattamento Fisioterapico 3 Sedute</>
            <>Trattamento Fisioterapico 5 Sedute</>
            <>Valutazione Della Forza Muscolare</>
            <>Valutazione Funzionale</>
            <>Valutazione Funzionale Fisioterapica</>
            <>Visita Domiciliare</>
            <>Visita Fisioterapica Di Controllo</>
            <>Visita Posturale</>
          </CompetenceListContainer>
        </StyledCompetenceList>
      </StyledCompetenceContainer>
      <ClearerElement></ClearerElement>
    </FullRow>
    <Contacts icons={data.footerJson.icons}></Contacts>
  </Layout>
);

ChiSono.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ChiSono;

export const query = graphql`
  query CurriculumQuery {
    chiSonoJson {
      pageTitle
      photoTitle
      subTitle
      sideImage {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      introImage {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      otherImage {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    homeJson {
      landing {
        logo {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    footerJson {
      icons {
        title
        link
        image {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import Competence from 'components/competence';

const CompetenceListContainer = ({ children }) => (
  <Consumer>
    {() =>
      React.Children.map(children, child => <Competence>{child}</Competence>)
    }
  </Consumer>
);

CompetenceListContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CompetenceListContainer;

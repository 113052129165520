import React from 'react';
import PropTypes from 'prop-types';
import { StyledCompetence } from './competence.css';

const Competence = ({ children, as = 'li', size }) => {
  return (
    <StyledCompetence as={as} size={size}>
      {children}
    </StyledCompetence>
  );
};

Competence.propTypes = {
  children: PropTypes.string.isRequired,
  as: PropTypes.string,
  size: PropTypes.oneOf(['large']),
};

export default Competence;

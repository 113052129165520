import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import Img from 'gatsby-image';

export const Title = styled.span`
  display: block;
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 2rem 1rem;
  color: #1a5d8f;
  text-align: center;
`;

export const Copy = styled.p`
  color: #757575;
  margin: 0 2rem 2rem;
  color: #1a5d8f;
  text-align: center;
`;

export const StyledFigure = styled.figure``;

export const StyledCaption = styled.figcaption``;

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 80vw;
  padding-bottom: 5rem;
  margin: auto;
  ${MEDIA.TABLET`
    flex-direction: column;
    align-items: center;
  `}
`;

export const BiographyContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 25vw;
  padding: 0 2rem;
  margin-bottom: 5rem;

  ${MEDIA.TABLET`
    margin-bottom: 0;
    max-width: 80vw;
  `};
`;

export const StyledListContainer = styled.div`
  padding-bottom: 1rem;
`;

export const StyledListEnder = styled.div`
  margin-top: 1rem;
  border-bottom: 2px solid #1a5d8f;
  width: 15%;
`;

export const StyledListTitle = styled.h2`
  font-size: 3rem;
  color: #1a5d8f;
  margin-bottom: 3rem;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  ${MEDIA.TABLET`
    width: 100%;
  `};
`;

export const SubTitle = styled.h2`
  color: #1a5d8f;
  font-size: 4rem;
  margin: 1rem 0;
  ${MEDIA.TABLET`
    text-align: center;
  `};
`;

export const StyledImageContainer = styled.div`
  display: flex;
  width: 50%;
  min-width: 25vw;
  justify-content: end;
  align-items: center;
  padding-right: 3rem;
  ${MEDIA.TABLET`
    width: 100%;
    padding-right: 0;
    justify-content: center;
  `}
`;

export const StyledImage = styled(Img)`
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
  ${MEDIA.TABLET`
    margin: auto;
    width: 50vw;
    height: 50vw;
  `}
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${MEDIA.TABLET`
    margin-left: 0;
    margin: auto;
    margin-top: 2rem;
  `};
`;

export const IconContainer = styled.div`
  display: flex;
  flex: 1 0 auto;
  /* justify-content: center; */
  align-items: center;
`;

export const StyledIconImg = styled(Img)`
  width: 1.5rem;
  display: inline-block;
  /* background-color: #fff; */
  padding: 1rem;
`;

export const StyledIconLink = styled.a`
  color: #1a5d8f;
  margin-left: 2rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding-top: 15vh;

  background-color: #faf8f6;

  max-width: 100vw;
  height: 85vh;

  ${MEDIA.TABLET`
    flex-direction: column;
    height: auto;
  `};
`;

export const FullRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vh 10vw;

  background-color: #faf8f6;

  height: auto;
  max-height: auto;

  max-width: 100vw;
  min-height: 85vh;

  ${MEDIA.TABLET`
    display:none;
  `};
`;

export const StyledCompetenceContainer = styled.div`
  display: flex;

  justify-content: space-around;
  align-items: center;

  width: 100%;

  ${MEDIA.TABLET`
    flex-wrap: nowrap;
    max-height: none;
    width: auto;
    flex-direction: column;
  `};
`;

export const StyledCompetenceList = styled.ul`
  max-height: 120vh;
  margin: 3rem 4rem;

  list-style: disc;
  margin: 0 auto;

  ${MEDIA.TABLET`
    display: none;
  `}
`;

export const ClearerElement = styled.div`
  clear: both;
  font-size: 0;
`;

export const AlternateFullRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5vh 10vw;
  height: auto;
  max-height: auto;
  max-width: 100vw;

  background-color: #fff;
  min-height: auto;

  ${MEDIA.TABLET`
    min-height: auto;
    max-height: auto;
  `};
`;
